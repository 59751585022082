import React, { useState, useEffect} from 'react';
import Modal from 'react-modal';
import apiService from '../../../services/apiService';
import CustomToast from '../../Toast';
import { useTranslation } from 'react-i18next';

const LanguageSelectModal = ({isOpen, onRequestClose, course, onConfirm}) => {
    const { i18n } = useTranslation();
    const [isOpenLangModal, setIsOpenLangModal] = useState(false)
    const [options, setOptions] = useState()
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedLang, setSelectedLang]  = useState()
    const [userSelectLang, setUserSelectLang]  = useState()
    const { t } = useTranslation();

    const openLanguageSelectModal = () => {
        setIsOpenLangModal(true)
    }

    const closeLanguageSelectModal = () => {
        onRequestClose();
    }

    const handleDropdownChange = (event) => {
        const selectedIndex = event.target.selectedIndex;
        const selectedOption = event.target[selectedIndex];
        const selectedValue = selectedOption.value;
        const selectedLabel = selectedOption.textContent;
        setUserSelectLang(selectedLabel)
        setSelectedLang(event.target.value);
        setSelectedLanguage(event.target.value)
        const obj = {
            lang_id: selectedValue,
            langName: selectedLabel,
            course_id: course?.new_course_id
        }
        localStorage.setItem('userLang', obj?.langName)
        localStorage.setItem('selectedLanguageInfo', JSON.stringify(obj))
        const langId = getLangCode(obj?.langName)
        i18n.changeLanguage(langId);
    }

    const handleSubmit = async()=> {
        let userInfo = sessionStorage.getItem('userDetail')
        if(userInfo) {
            userInfo = JSON.parse(userInfo)
            const selectedLangCourse = await getCourseList(userInfo, selectedLang)
            if(selectedLangCourse && selectedLangCourse?.data?.course_list?.length > 0) {
                const obj = {
                    uid: userInfo.uid,
                    lang_id: selectedLang, 
                    course_name: course?.course_name,
                    course_id: course?.new_course_id,
                    lang: userSelectLang
                }
                // setUserSelectLang(selectedLabel)
                insertUserLang(obj)
                onConfirm()
                onRequestClose();
            } else {
                CustomToast.info(t('toast_messages_selected_language_not_available_error'));
            }
        }
    }

    const getLangCode = (lang) => {
        switch (lang) {
          case 'English':
            return 'en'; 
          case 'Hindi':
            return 'hi';
          case 'Marathi':
            return 'mar';
          default:
            return 'en';
        }
      };

    useEffect(()=> {
        if (options?.length > 0) {
            setSelectedLang(options[0].lang_id);
            const obj = {
                lang_id: options[0].lang_id,
                langName: options[0].lang,
                course_id: course?.new_course_id
            }
            localStorage.setItem('userLang', obj?.langName)
            localStorage.setItem('selectedLanguageInfo', JSON.stringify(obj))
        }
    }, [options])

    const getLang=async()=>{
        const token = apiService.getAuthToken()
        try {
        const languagesData = await apiService.getLmsv1(`courseLangList/${course?.new_course_id}`, token)
        if (languagesData.status) {
            setOptions(languagesData.data);
        } else {
          console.error("Error fetching languages:", languagesData.error);
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    }

    useEffect(() => {
        if (isOpen) {
            getLang();
        }
    }, [isOpen]);

    // useEffect(()=> {
    //     if(options?.length === 1){
    //         const defaultOption = options[0];
    //         const obj = {
    //             lang_id: defaultOption?.lang_id,
    //             langName: defaultOption?.lang,
    //             course_id: course?.new_course_id
    //         }
    //         localStorage.setItem('userLang', obj?.langName);
    //         localStorage.setItem('selectedLanguageInfo', JSON.stringify(obj));
    //         setSelectedLang(defaultOption?.lang_id);
    //         setSelectedLanguage(defaultOption?.lang_id);
    //         const langId = getLangCode(obj?.langName);
    //         i18n.changeLanguage(langId);

    //         console.log("default option:", defaultOption?.lang);

    //         onConfirm();
    //         onRequestClose();
    //     }
    // }, [options, i18n, onConfirm, onRequestClose, course])

    // if(options?.length === 1) {
    //     return null;
    // }

    const getCourseList = (user_details, lang) => {
        const token = apiService.getAuthToken()
        return apiService.getLmsv1(`courseCardList?user_id=${user_details?.uid}&lang_id=${lang}`, token)
    }

    const insertUserLang = async(payload)=> {
        try {
            const response = await apiService.post('save-lms-user-course-info', payload);
            if (response && response.status === 'success') {
                console.log(response)
            } else {
                // setError('Failed to update profile');
                // CustomToast.error('Error while updating user')
            }
            // setLoader(false);
        } catch (error) {
            console.log(error)
            // CustomToast.error('Error while updating user')
        }
    }

    return (
        <div className='card language-popup d-flex flex-column justify-content-center align-items-center text-center border-0 mb-4'>
            <a onClick={closeLanguageSelectModal} className='w-100 text-end test-decoration-none'><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
            </svg>
            </a>
           
            <h3 className='fw-bold font-26 my-4'>{t('home_page_select_language')}</h3>
            <p className='mb-4'>{t('home_page_choose_language')}</p>
            <div className='w-75 mb-3 m-auto'> 
                <select 
                    className='form-select position-relative w-100 h-40 fw-bold' 
                    value={selectedLang} 
                    onChange={handleDropdownChange}
                    >
                    {options?.map((data) => (
                        <option key={data?.lang_id} value={data?.lang_id}>{data?.lang}</option>
                      ))}
                </select>
            </div>
            <div className='d-flex justify-content-center align-items-center w-100 mt-3'>
                <button className='btn btn-md btn-primary' onClick={handleSubmit}>{t('home_page_confirm')}</button>
            </div>
        </div>
       
    
    )
}

export default LanguageSelectModal;