import React, { useState, useEffect } from 'react';
import './myProfile.css';
import apiService from '../../../services/apiService';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';
import { useTranslation } from 'react-i18next';

const MyProfileDetails = ({ onEditClick }) => {

    const [userData, setUserData] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();
    
    const fetchData = async () => {
        try {
            const token = apiService.getAuthToken()
            const result = await apiService.get('get-org-user-info', token);
            if(result && result.status == "success") {
                sessionStorage.setItem('user_details', JSON.stringify(result?.data))
                setUserData(result?.data);
            }
            setLoader(false);
        } catch (error) {
            setError('Error fetching user data');
            console.error(error);
            setLoader(false);
            // CustomToast.error('Error fetching user data');
            if (error == "Invalid token id!") {
                // Clear the token from local storage
                localStorage.removeItem('authToken');
                sessionStorage.removeItem('user_details');
                // Redirect to the original website
                // if (process.env.REACT_APP_ENV == "development") {
                //     window.location.href = 'https://staging.inclusional.com';
                // } else {
                //     window.location.href = 'https://site.inclusional.com';
                // }
                window.location.href = process.env.REACT_APP_LOGIN_URL
                //process.env.REACT_APP_LOGIN_URL
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const getInitials = (name) => {
        return name?.split(' ').map(word => word.charAt(0)).join('').toUpperCase() || '';
    };

    let userDetails = sessionStorage.getItem('userDetail')
    if(userDetails) {
        userDetails = JSON.parse(userDetails)
        console.log(userDetails?.org_id)
    }
    console.log("userDetails :", userDetails);
    
    return (
        <div className='row'>
            <div className='col-lg-9 col-md-offset-1 m-auto'>
                <div className='profile-details-wrapper card m-lg-3 m-0 p-lg-3 p-0'>
                    <div className='card-body'>
                        <div className='row d-flex justify-content-end align-items-center'> <a onClick={onEditClick} className='d-flex justify-content-end align-items-center font-weight-600 font-20'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20.875 20.75V3.25H3.375V20.75H20.875ZM20.875 0.75C21.538 0.75 22.1739 1.01339 22.6428 1.48223C23.1116 1.95107 23.375 2.58696 23.375 3.25V20.75C23.375 22.1375 22.25 23.25 20.875 23.25H3.375C2.71196 23.25 2.07607 22.9866 1.60723 22.5178C1.13839 22.0489 0.875 21.413 0.875 20.75V3.25C0.875 2.58696 1.13839 1.95107 1.60723 1.48223C2.07607 1.01339 2.71196 0.75 3.375 0.75H20.875ZM18 8.6875L16.75 9.9375L14.1875 7.375L15.4375 6.125C15.7 5.85 16.1375 5.85 16.4 6.125L18 7.725C18.275 7.9875 18.275 8.425 18 8.6875ZM5.875 15.675L13.45 8.1L16.025 10.675L8.45 18.25H5.875V15.675Z" fill="#146B90" />
                            </svg>{t('my_profile_edit')}</a></div>  

                        {loader ? <Loader />
                        :  <div className='row justify-content-between align-items-center p-lg-5 p-0 m-auto'>
                        <div className='col-md-6 py-5 text-center'>
                                {userData && userData?.pic && userData?.pic != "null"  && userData?.pic != null ? (
                                    <img src={userData?.pic}  className='profile-img mb-4' />
                                ) : (
                                    <div className='custom-image-upload mb-4'>
                                        <span className='username-initials fw-bolder font-28 text-white'>{getInitials(userData?.name)}</span>
                                    </div>
                                )}

                            <h3 className='font-weight-600 text-capitalize font-26'>{userData?.name}</h3>
                            <p className='font-weight-600 text-uppercase font-14 designation'>{userData && userData.designation !== "undefined" && userData.designation !== "null" ? userData.designation : ''}</p>
                            
                        </div>
                                <div className='col-md-6 py-lg-5 pt-0 pb-5'>
                                    <div>
                                        <p className='font-18 d-flex align-items-center'><span>
                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_5641_101093)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 36.333C8.57476 36.333 0.5 28.2582 0.5 18.333C0.5 8.40777 8.57476 0.333008 18.5 0.333008C28.4252 0.333008 36.5 8.40777 36.5 18.333C36.5 28.2582 28.4252 36.333 18.5 36.333ZM27.8345 24.6563C28.0565 24.6563 28.2371 24.4757 28.2371 24.2537V12.9533L19.0789 20.2259C18.9094 20.3605 18.7046 20.4278 18.4999 20.4278C18.2952 20.4278 18.0905 20.3605 17.921 20.2259L8.76284 12.9533V24.2537C8.76284 24.4757 8.94348 24.6563 9.16538 24.6563H27.8345ZM26.4311 12.0097L18.5 18.308L10.5688 12.0097H26.4311ZM30.0992 12.4123V24.2539C30.0992 25.5026 29.0832 26.5185 27.8345 26.5185H9.16538C7.91663 26.5185 6.90083 25.5026 6.90083 24.2539V12.4123C6.90083 11.1636 7.9167 10.1477 9.16538 10.1477H27.8345C29.0832 10.1476 30.0992 11.1636 30.0992 12.4123Z" fill="#146B90" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_5641_101093">
                                                        <rect width="36" height="36" fill="white" transform="translate(0.5 0.333008)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>{userData && userData?.email.length > 30 ? <span className='font-18 product-link'>{(userData?.email.slice(0, 30)) + '...'}<a className="copy-link">{userData?.email}</a></span> : (userData?.email)} </p>
                                        <p className='font-18'><span><svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_5641_101097)">
                                                <path d="M23.523 8.62988H13.477C13.0239 8.62988 12.6552 8.99853 12.6552 9.45162V27.2143C12.6552 27.6674 13.0239 28.0361 13.477 28.0361H23.523C23.9761 28.0361 24.3448 27.6675 24.3448 27.2143V9.45162C24.3448 8.99853 23.9761 8.62988 23.523 8.62988ZM18.5 26.0078C17.521 26.0078 16.7226 25.2093 16.7226 24.2303C16.7226 23.2514 17.521 22.4529 18.5 22.4529C19.479 22.4529 20.2774 23.2514 20.2774 24.2303C20.2774 25.2093 19.479 26.0078 18.5 26.0078ZM18.5 0.333008C8.57476 0.333008 0.5 8.40777 0.5 18.333C0.5 28.2582 8.57476 36.333 18.5 36.333C28.4252 36.333 36.5 28.2582 36.5 18.333C36.5 8.40777 28.4252 0.333008 18.5 0.333008ZM26.0323 27.2143C26.0323 28.5979 24.9066 29.7236 23.523 29.7236H13.477C12.0933 29.7236 10.9677 28.5979 10.9677 27.2143V9.45162C10.9677 8.06802 12.0934 6.94238 13.477 6.94238H23.523C24.9067 6.94238 26.0323 8.06802 26.0323 9.45162V27.2143Z" fill="#F59390" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_5641_101097">
                                                    <rect width="36" height="36" fill="white" transform="translate(0.5 0.333008)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        </span>{userData?.mobile}</p>
                                        {userDetails && userDetails?.org_id != '7c13b617-55a1-4ea6-810a-7bf05aaf52af' ? <>
                                        <p className='font-18'><span>
                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_5641_101103)">
                                                    <path d="M23.1672 12.8221C23.1672 15.3956 21.0735 17.4893 18.5 17.4893C15.9265 17.4893 13.8328 15.3956 13.8328 12.8221V8.98152H23.1672V12.8221ZM13.8328 23.844V27.6845H17.6562V24.4775C17.6562 24.0114 18.034 23.6337 18.5 23.6337C18.966 23.6337 19.3438 24.0114 19.3438 24.4775V27.6845H23.1672V23.844C23.1672 21.2705 21.0735 19.1768 18.5 19.1768C15.9265 19.1768 13.8328 21.2705 13.8328 23.844ZM36.5 18.333C36.5 28.2582 28.4252 36.333 18.5 36.333C8.57476 36.333 0.5 28.2582 0.5 18.333C0.5 8.40777 8.57476 0.333008 18.5 0.333008C28.4252 0.333008 36.5 8.40777 36.5 18.333ZM21.6617 18.333C23.5683 17.2349 24.8547 15.1759 24.8547 12.8221V8.98152H26.3854C26.8514 8.98152 27.2292 8.60373 27.2292 8.13777C27.2292 7.6718 26.8514 7.29402 26.3854 7.29402H10.6146C10.1486 7.29402 9.77084 7.6718 9.77084 8.13777C9.77084 8.60373 10.1486 8.98152 10.6146 8.98152H12.1454V12.8221C12.1454 15.1759 13.4317 17.2349 15.3384 18.333C13.4317 19.4311 12.1454 21.4901 12.1454 23.844V27.6845H10.6146C10.1486 27.6845 9.77084 28.0623 9.77084 28.5282C9.77084 28.9942 10.1486 29.372 10.6146 29.372H26.3854C26.8514 29.372 27.2292 28.9942 27.2292 28.5282C27.2292 28.0623 26.8514 27.6845 26.3854 27.6845H24.8547V23.844C24.8547 21.4901 23.5683 19.4311 21.6617 18.333Z" fill="#F4B532" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_5641_101103">
                                                        <rect width="36" height="36" fill="white" transform="translate(0.5 0.333008)" />
                                                    </clipPath>
                                                </defs>
                                            </svg></span>{userData && userData?.age_group !== "undefined" && userData?.age_group !== "null" ? userData?.age_group : ''}</p>
                                        <p className='font-18'><span>
                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="18.5" cy="18.333" r="18" fill="#22A298" />
                                                <path d="M28.4971 7.91902C28.4738 7.59547 28.1829 7.32795 27.8624 7.33315H23.9177C23.5655 7.33315 23.2801 7.62173 23.2801 7.97768C23.2801 8.33363 23.5655 8.62221 23.9177 8.62221H26.3229L21.6268 13.3689C20.7864 12.6374 19.6935 12.1945 18.5001 12.1945C17.3066 12.1945 16.2137 12.6374 15.3734 13.3689L13.8437 11.8228L14.9069 10.7481C15.1559 10.4965 15.1559 10.0883 14.9069 9.83663C14.658 9.58496 14.2542 9.58496 14.0052 9.83663L12.942 10.9114L10.6772 8.62216H13.0824C13.4346 8.62216 13.72 8.33359 13.72 7.97763C13.72 7.62168 13.4346 7.33311 13.0824 7.33311H9.13771C8.79553 7.32675 8.49376 7.63165 8.5001 7.97763V11.9651C8.5001 12.3211 8.78558 12.6096 9.13771 12.6096C9.48985 12.6096 9.77533 12.3211 9.77533 11.9651V9.53361L12.0402 11.8229L10.977 12.8977C10.728 13.1494 10.728 13.5575 10.977 13.8092C11.1015 13.9351 11.2647 13.998 11.4278 13.998C11.591 13.998 11.7542 13.9351 11.8787 13.8092L12.9419 12.7344L14.5238 14.3333C14.0055 15.1076 13.7025 16.0407 13.7025 17.044C13.7025 19.4995 15.5174 21.5344 17.8624 21.8505V25.0855H16.0919C15.7398 25.0855 15.4543 25.3741 15.4543 25.73C15.4543 26.086 15.7398 26.3745 16.0919 26.3745H17.8624V28.6885C17.8624 29.0444 18.1479 29.333 18.5 29.333C18.8521 29.333 19.1376 29.0444 19.1376 28.6885V26.3745H20.908C21.2602 26.3745 21.5457 26.086 21.5457 25.73C21.5457 25.3741 21.2602 25.0855 20.908 25.0855H19.1376V21.8505C21.4826 21.5343 23.2974 19.4995 23.2974 17.044C23.2974 16.0407 22.9945 15.1076 22.4762 14.3333L27.2248 9.53361V11.9651C27.2248 12.3211 27.5103 12.6096 27.8624 12.6096C28.2145 12.6096 28.5 12.3211 28.5 11.9651V7.97763C28.5 7.95791 28.4988 7.9384 28.4971 7.91902ZM18.5001 20.6045C16.5579 20.6045 14.9778 19.0072 14.9778 17.044C14.9778 15.0809 16.5579 13.4836 18.5001 13.4836C20.4422 13.4836 22.0223 15.0809 22.0223 17.044C22.0223 19.0072 20.4422 20.6045 18.5001 20.6045Z" fill="white" stroke="white" stroke-width="0.35" />
                                            </svg>
                                        </span>{userData && userData?.gender !== "undefined" && userData?.gender !== "null" ? userData?.gender : ''}</p>
                                        </> : ' ' }
                                    </div>

                                </div>
                    </div>}

                       {/* <div className='text-center'><p className='mb-0'>“Morbi nisi erat egestas scelerisque lectus justo senectus magna. Lorem ipsum dolor sit”</p></div>*/} 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfileDetails;